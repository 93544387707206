// # App concept
// This is used for general app state
import get from 'lodash/get';
import set from 'lodash/set';

import config from '../config';
import { fetchStatus } from 'services/api';
import { initializeSiteSelection, getCurrentSiteId } from 'concepts/sites';
import { fetchInternationalization } from 'concepts/i18n';
import { initializeCampaign } from 'concepts/campaigns';
import { fetchSiteMediaTrackers } from 'concepts/media-trackers';
import { fetchUser } from 'concepts/user';
import CsrfTokenPath from 'constants/CsrfTokenPath';

// # Action Types
const CHECK_STATUS = 'app/CHECK_STATUS';
const SET_APP_STATUS_OK = 'app/SET_APP_STATUS_OK';
const SET_APP_STATUS_FAILED = 'app/SET_APP_STATUS_FAILED';
const SET_NAVIGATION_VISIBILITY = 'app/SET_NAVIGATION_VISIBILITY';

// # Selectors
export const getAppStatus = state => state.app.isAppStatusOk;
export const getSidenavOpenStatus = state => state.app.isSidenavOpen;

// # Action Creators
export const checkAppStatus = () => dispatch => {
  dispatch({ type: CHECK_STATUS });
  return fetchStatus()
    .then(response => {
      if (get(response, 'error.code') === 401) {
        window.location.replace(`${config.flocklerUrl}/login?redirect=${window.location}`);
      }

      // Set csrf-token to window object
      const csrfToken = response?.data?.csrf_token;
      set(window, CsrfTokenPath, csrfToken || '');
      return;
    })
    .catch(error => {
      if (get(error, 'response.status') === 401) {
        window.location.replace(`${config.flocklerUrl}/login?redirect=${window.location}`);
      }

      return dispatch({ type: SET_APP_STATUS_FAILED });
    });
};

export const startApp = () => (dispatch, getState) => {
  return dispatch(checkAppStatus())
    .then(() => dispatch(fetchUser()))
    .then(() =>
      Promise.all([dispatch(fetchInternationalization()), dispatch(initializeSiteSelection())])
    )
    .then(() => dispatch(initializeCampaign()))
    .then(() => {
      const siteId = getCurrentSiteId(getState());
      dispatch(fetchSiteMediaTrackers(siteId));
    })
    .then(() => dispatch({ type: SET_APP_STATUS_OK }));
};

export const toggleSidenav = () => (dispatch, getState) => {
  const isOpen = getSidenavOpenStatus(getState());

  return dispatch({ type: SET_NAVIGATION_VISIBILITY, payload: !isOpen });
};
export const closeSidenav = () => ({ type: SET_NAVIGATION_VISIBILITY, payload: false });

// # Reducer
const initialState = {
  isAppStatusOk: false,
  isSidenavOpen: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_APP_STATUS_OK: {
      return { ...state, isAppStatusOk: true };
    }

    case SET_APP_STATUS_FAILED: {
      return { ...state, isAppStatusOk: false };
    }

    case SET_NAVIGATION_VISIBILITY: {
      return { ...state, isSidenavOpen: action.payload };
    }

    default: {
      return state;
    }
  }
}
