import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getCurrentSiteUrl } from 'concepts/sites';
import { hasBasicDisplayFeedContent } from 'concepts/dashboard';
import config from 'config';

import { ReactComponent as InfoIcon } from 'assets/svgs/icon-info-circle.svg';
import styles from './InstagramBasicDisplayContentBanner.module.scss';

const InstagramBasicDisplayContentBanner = ({ hasBasicDisplayFeedContent, siteUrl }) => {
  if (!hasBasicDisplayFeedContent) return null;

  return (
    <div className={styles.banner}>
      <div className={styles.bannerContent}>
        <InfoIcon className={styles.bannerIcon} />{' '}
        <span>
          Some of the Instagram posts don’t have analytics data. To make sure Flockler is able to
          gather likes and comments, please create{' '}
          <a
            href={`${config.flocklerAppUrl}/${siteUrl}/feeds`}
            target="_blank"
            rel="noopener noreferrer"
          >
            auto-updating feeds
          </a>{' '}
          using the Instagram Business account.
        </span>
      </div>
    </div>
  );
};

InstagramBasicDisplayContentBanner.propTypes = {
  hasBasicDisplayFeedContent: PropTypes.bool.isRequired,
  siteUrl: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  hasBasicDisplayFeedContent: hasBasicDisplayFeedContent(state),
  siteUrl: getCurrentSiteUrl(state),
});

export default connect(mapStateToProps)(InstagramBasicDisplayContentBanner);
